/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import IframeResizer from "iframe-resizer-react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    h3: "h3",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Waarom is de LTA Antistollingszorg ontwikkeld?"), "\n", React.createElement(_components.p, null, "De Landelijke Transmurale Afspraak (LTA) Antistollingszorg is een praktische tool waarmee elke arts, tandarts of verpleegkundige snel de weg weet te vinden over hoe om te gaan met antitrombotica en onderbreken bij ingrepen en wat te doen bij bloedingen. Daarnaast is een paragraaf voor communicatie met patiënten opgenomen. De tool is te gebruiken op een laptop en mobiele telefoon."), "\n", React.createElement(_components.p, null, "De LTA geeft richting aan de samenwerking tussen:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "de medisch specialist"), "\n", React.createElement(_components.li, null, "huisarts"), "\n", React.createElement(_components.li, null, "trombosedienst"), "\n", React.createElement(_components.li, null, "openbaar apotheker"), "\n", React.createElement(_components.li, null, "specialist ouderengeneeskunde"), "\n", React.createElement(_components.li, null, "tandartsen en mondhygiënisten"), "\n"), "\n", React.createElement(_components.h3, null, "Onderwerpen"), "\n", React.createElement(_components.p, null, "Op deze website vindt u onder meer informatie over het voorschrijven en de follow-up van antitrombotica, periprocedureel beleid en het beleid bij bloedingen. Ook vindt u op deze website informatie over onderwerpen die belangrijk zijn in de communicatie met patiënten."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, null, "Interactieve content"), "\n", React.createElement(_components.p, null, "Voor de onderdelen periprocedureel beleid en beleid bij bloedingen zijn interactieve beslisbomen ontwikkeld. Deze beslisbomen zijn ook in jouw eigen website, protocol of e-learning te gebruiken. Hieronder zie je daar een voorbeeld van."), "\n", React.createElement("div", {
    style: {
      marginTop: "20px",
      marginBottom: "5px",
      fontSize: "12px"
    }
  }, "Beslisboom via LTA Antistollingszorg"), "\n", React.createElement(IframeResizer, {
    src: "https://www.inflowchart.com/chart/lta-antistolling/periprocedureel-beleid-apixaban/collapse",
    style: {
      width: "1px",
      minWidth: "100%",
      border: "1px solid #ddd",
      margin: "0px 0 20px 0"
    }
  }), "\n", React.createElement("div", {
    style: {
      marginTop: "20px",
      marginBottom: "5px",
      fontSize: "12px"
    }
  }, "Beslisboom via Kenniscentrum Antistolling Transmuraal Noord-Holland"), "\n", React.createElement(IframeResizer, {
    src: "https://www.inflowchart.com/chart/katnh/periprocedureel-beleid-apixaban",
    style: {
      width: "1px",
      minWidth: "100%",
      border: "1px solid #ddd",
      margin: "0px 0 20px 0"
    }
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;

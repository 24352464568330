import GATSBY_COMPILED_MDX from "/opt/build/repo/src/artikelen/over-de-lta-antistollingszorg.mdx";
import * as React from "react";
import LayoutMain from "components/layout/main";
import SectionWrapper from "components/section/sectionWrapper";
import {Col, Grid, Row, Stack, Tag, Divider} from "rsuite";
function ArtikelPage({children, pageContext}) {
  return React.createElement(LayoutMain, null, React.createElement(SectionWrapper, {
    padded: true,
    background: true
  }, React.createElement(Grid, null, React.createElement(Row, null, React.createElement(Col, null, React.createElement("h1", {
    style: {
      color: "#000"
    }
  }, pageContext.frontmatter.title), React.createElement(Stack, {
    divider: React.createElement(Divider, {
      vertical: true
    })
  }, React.createElement(React.Fragment, null, "Door: ", pageContext.frontmatter.author), React.createElement(Tag, null, pageContext.frontmatter.date)))))), React.createElement(SectionWrapper, {
    padded: true
  }, React.createElement(Grid, null, React.createElement(Row, null, React.createElement(Col, {
    style: {
      fontSize: "18px"
    },
    className: "markdown"
  }, children)))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ArtikelPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
